<template>
  <header
    class="theme--light v-navigation-drawer  elevation-1"
    style="height: 50px; border-radius: 5px; margin-bottom: 10px"
  >
    <div class="v-toolbar__content" style="height: 50px; padding: 25px">
      <div class="v-toolbar__title">
        <h4 class="black--text">{{ title }}</h4>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  }
};
</script>

<style></style>
