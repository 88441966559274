var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.onAddingProcess ? _c('h3', {
    staticClass: "py-4"
  }, [_vm._v("Add New Tenant")]) : _vm._e(), _c('div', [!_vm.onAddingProcess ? _c('v-btn', {
    staticStyle: {
      "margin-right": "65px"
    },
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "medium": "",
      "dark": "",
      "color": "accent"
    },
    on: {
      "click": _vm.addNewTenant
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _c('v-btn', {
    staticStyle: {
      "margin-right": "65px"
    },
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "medium": "",
      "dark": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        _vm.onAddingProcess = false;
        _vm.selectedTenant = _vm.tenants[0].id;
        _vm.getTenantDetail();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "my-2",
    on: {
      "click": function ($event) {
        return _vm.saveTenant();
      }
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "greeny",
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "medium": "",
      "dark": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save-outline")])], 1)], 1), !_vm.onAddingProcess ? _c('v-select', {
    attrs: {
      "label": "Tenant",
      "items": _vm.tenants,
      "item-text": "name",
      "item-value": "id",
      "solo": "",
      "required": ""
    },
    on: {
      "change": _vm.getTenantDetail
    },
    model: {
      value: _vm.selectedTenant,
      callback: function ($$v) {
        _vm.selectedTenant = $$v;
      },
      expression: "selectedTenant"
    }
  }) : _vm._e(), _c('div', [_c('InformationCollapse', {
    attrs: {
      "onAddingProcess": _vm.onAddingProcess,
      "tenant": _vm.data,
      "currencies": _vm.currencies,
      "locales": _vm.locales
    }
  }), _c('SilverrailCredentials', {
    attrs: {
      "onAddingProcess": _vm.onAddingProcess,
      "loading": _vm.dataLoader,
      "data": _vm.data.silverrailCredentials
    }
  }), _c('ShippingCredentials', {
    attrs: {
      "tenant": _vm.data,
      "onAddingProcess": _vm.onAddingProcess
    }
  }), _c('KlarnaCredentials', {
    attrs: {
      "onAddingProcess": _vm.onAddingProcess,
      "loading": _vm.dataLoader,
      "data": _vm.data.klarnaCredentials
    }
  }), _c('SystemObjects', {
    attrs: {
      "onAddingProcess": _vm.onAddingProcess,
      "loading": _vm.dataLoader,
      "data": _vm.data.systems,
      "systemObjects": _vm.systemObjects
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }