var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-speed-dial', {
    staticStyle: {
      "margin-right": "65px"
    },
    attrs: {
      "direction": "left",
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "dark": "",
      "transition": _vm.transition
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "blue darken-2",
            "dark": "",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v(" mdi-close ")]) : _c('v-icon', [_vm._v(" mdi-account-circle ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "green"
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('add');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "danger"
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }