<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    class="elevation-1 my-7"
    :class="{ 'on-add': onAddingProcess }">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Klarna Credentials</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on"> New Item </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.environment"
                      :items="Environments"
                      menu-props="auto"
                      hide-details
                      item-text="text"
                      item-value="value"
                      label="Environment"
                      single-line></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field hide-details="auto" v-model="editedItem.username" label="Username"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field hide-details="auto" v-model="editedItem.password" label="Password"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.whitelistedCountries"
                      :items="filteredCountries"
                      multiple
                      chips
                      deletable-chips
                      label="Whitelisted Countries">
                      <template v-slot:prepend-item>
                        <div class="search-bar">
                          <v-text-field
                            v-model="searchCountry"
                            placeholder="Search Country"
                            hide-details
                            clearable></v-text-field>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="pr-8 pb-8">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close"> Cancel </v-btn>
              <v-btn color="accent" @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
              <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small color="third" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small color="primary" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <!--<v-btn color="primary" @click="initialize">
        Reset
      </v-btn>-->
    </template>
  </v-data-table>
</template>

<script>
import editableTableMixin from "@/views/TenantManagement/mixins/editableTableMixin";
import countryList from "@/static/countries.json";

export default {
  mixins: [editableTableMixin],
  props: ["data", "loading", "onAddingProcess"],
  data: () => ({
    headers: [
      { text: "Environment", align: "start", sortable: false, value: "environment" },
      { text: "Username", value: "username" },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
    ],
    editedItem: {
      environment: "",
      username: "",
      password: "",
      whitelistedCountries: [],
    },
    defaultItem: {
      environment: "",
      username: "",
    },
    countries: countryList,
    searchCountry: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    filteredCountries() {
      if (this.dialog) {
        let filteredCountries = [];
        const selectedItems = this.countries.filter((country) => {
          return this.editedItem.whitelistedCountries && this.editedItem.whitelistedCountries.includes(country.value);
        });
        this.editedItem.whitelistedCountries = selectedItems.map((country) => country.value);
        if (this.searchCountry) {
          filteredCountries = this.countries.filter((country) => {
            return country.text.toLowerCase().includes(this.searchCountry.toLowerCase());
          });
          filteredCountries = filteredCountries.concat(selectedItems);
        } else {
          filteredCountries = this.countries.concat(selectedItems);
        }
        return filteredCountries;
      }
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchCountry = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  position: sticky;
  top: 0;
  padding: 0 16px 16px 16px;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid #e0e0e0;
}
</style>
