var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v(_vm._s(_vm.title))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }