<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    class="elevation-1 mb-7"
    :class="{ 'on-add': onAddingProcess }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Silverrail Credentials</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.applicationName"
                      label="Application Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="editedItem.environment"
                      :items="Environments"
                      menu-props="auto"
                      hide-details
                      item-text="text"
                      item-value="value"
                      label="Environment"
                      single-line
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.salesUnit" label="Sales Unit"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.currency" label="Currency"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.username" label="Username"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :value="editedItem.password"
                      v-model="editedItem.password"
                      label="Password"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="pr-8 pb-8">
              <v-spacer></v-spacer>
              <v-btn color="primary"  @click="close">
                Cancel
              </v-btn>
              <v-btn color="accent"  @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary"  @click="closeDelete">Cancel</v-btn>
              <v-btn color="accent"  @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small color="third" class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small color="primary" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!--<v-btn color="primary" @click="initialize">
        Reset
      </v-btn>-->
    </template>
  </v-data-table>
</template>

<script>
import editableTableMixin from "@/views/TenantManagement/mixins/editableTableMixin";

export default {
  mixins: [editableTableMixin],
  props: ["data", "loading", "onAddingProcess"],
  data: () => ({
    headers: [
      { text: "Application Name", align: "start", sortable: false, value: "applicationName" },
      { text: "Environment", align: "start", sortable: false, value: "environment" },
      { text: "Currency", value: "currency" },
      { text: "Sales Unit", value: "salesUnit" },
      { text: "Username", value: "username" },
      { text: "Actions", value: "actions", sortable: false, align: "right" }
    ],
    editedItem: {
      applicationName: "",
      environment: "",
      currency: "",
      salesUnit: "",
      username: "",
      password: ""
    },
    defaultItem: {
      applicationName: "",
      environment: "",
      currency: "",
      salesUnit: "",
      username: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Silverrail Credential" : "Edit Silverrail Credential";
    }
  }
};
</script>
